import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import LineBreak from "../layout/lineBreak"
import CareersList from "../layout/careersList"
import Grid from "../layout/grid"
import DeAndIPageCard from "../layout/deAndIPageCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import PdfDownloadList from "../layout/pdfDownloadList"
import PdfDownload from "../layout/pdfDownload"
import RainbowText from '../blocks/rainbowText';
import GridContainer from '../blocks/GridContainer';
import RoundedCTA from '../blocks/RoundedCTA';
import ImageHero from "../layout/imageHero"
import { widont } from "../../utils/utils"


class DeAndI extends Component {
	constructor(props) {
		super(props)
		this.state = {
			requestData: null,
		}
	}

	_widont() {
		widont(`.t-bc, .richtext h2`)
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/de-and-i")
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data,
		})
		this._widont()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	render() {
		const { requestData } = this.state
		const { openVideoOverlay } = this.props
		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.deAndIPage.heroImage,
				title = requestData.deAndIPage.title,
				hideTitle = requestData.deAndIPage.hideTitle,
				content = requestData.deAndIPage.content,
				heroVideo = requestData.deAndIPage.heroVideo,
				goals = requestData.deAndIPage.goals,
				downloads = requestData.deAndIPage.downloads,
				emailContact = requestData.deAndIPage.emailContact,
				otherDetails = requestData.deAndIPage.otherDetails,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
				<>
					<Helmet>
						<title>{metaTitle}</title>
						<link rel="canonical" href={metaUrl} />
						<meta name="description" content={metaDesc} />
						<meta property="og:url" content={metaUrl} />
						<meta property="og:title" content={metaTitle} />
						<meta property="og:description" content={metaDesc} />
						<meta property="og:image" content={metaImage} />
						<meta name="twitter:title" content={metaTitle} />
						<meta name="twitter:description" content={metaDesc} />
						<meta name="twitter:image" content={metaImage} />
						<meta name="twitter:image:alt" content={metaTitle} />
					</Helmet>


					<ImageHero
						marginDisabled
						overlayDisabled
						images={heroImage}
						heroTitle={hideTitle ? "" : title}
					/>

					{/* content */}
					<section className={`site-content ${!content ? "no-top-padding" : ""}`}>
						<Fade fraction={0.2}>
							<GridContainer
								className="df aic mb1rem"
								size="small"
							>
								<section className="richtext module-margin module-margin-disabled drop-last-padding text-left">
									<span>
										<div className="fz32 lh1.3 fz44@md">
											FCB as a network has been deeply committed to and recognized for our work on&nbsp;
											<RainbowText className="mr10 ff-nfd fsi">
												<span>equality and inclusion.</span>
											</RainbowText></div>
										<div className="t-bc" style={{ lineHeight: "0", paddingBottom: "1rem" }}><img src="https://d1f5kcwhveewqf.cloudfront.net/static/public/img/img/rainbow-hr.png"></img></div>
									</span>
								</section>
							</GridContainer>
						</Fade>


						{/* goals content */}
						<Fade fraction={0.2}>
							<GridContainer
								className="df aic mb7rem fxdrc fxdrr@md"
								size="small"
							>
								<div className="w65%@md pr15@md pr0 w100%">
									<div
									>
										<p className="ff-nfd">Our team has worked cross-functionally to create a detailed and unique process for structural inclusion. When you add that to the work we have already done in behavioral inclusion, you get a very real cultural change&nbsp;initiative.</p>
									</div>
								</div>
								<div className="w35%@md w100% pl0 pl15@md ">
									<div
										className=""
									>
										<p><img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/dei-logo.png" className="maw100% m-auto db mt7rem mt0@md"></img></p>
									</div>
								</div>
							</GridContainer>
						</Fade>

						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<section className="richtext module-margin module-margin-disabled drop-last-padding text-left ">
									<span>
										<div className="t-h4">
											The aim with our plan is to create universal cultural 
											competence with a shared responsibility to make 
											this the new norm. It includes detailed actions on 
											three fronts:&nbsp;
											<RainbowText className="mr10 ff-nfd fsi">
												<span>workforce, workplace </span>
											</RainbowText>
											and the&nbsp;
											<RainbowText className="mr10 ff-nfd fsi">
												<span>creative work.</span>
											</RainbowText>
										</div>
									</span>
								</section>
							</Intro>
						</Fade>
						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<section className="richtext module-margin module-margin-disabled text-left ">
									<span>
										<p>
											<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/dei-graphic.png" className="pt3rem pb1rem w100%"></img>
										</p>
										<p>For <em>workforce</em> our plan enforces the full diversity of the general population — across all levels including leadership — and ensures that leadership adopts innovative ways to foster and retain talent. </p>

										<p>With the <em>workplace</em> our plan seeks to create a healthy and vibrant environment that is inclusive and engaged and that actively roots out bias and oppression in any form.</p>

										<p>Which then takes us to <em>creative work</em> where our aim is to create culturally competent work and solutions that serve our overarching goals tied to tracked KPIs. And with the 456 and The WOW, we’ve put big, award-winning work that helps combat racism and promote inclusion into the world. We created The WOW to help develop cultural competence and mitigate the negative impacts of unconscious bias in our work. Diversity, Equity &amp; Inclusion (DE&amp;I) issues are easier to discuss when you have the right terminology and context for the conversation. And being able to identify issues early in the creative process through productive conversations allows marketers to discourage racial stereotypes, rather than reinforce them.
										</p>

										
										{/* <span>
											<RoundedCTA className="mr10 w100% wauto@md">
												<a className="" href="https://www.wow.com">Visit our WoW site</a>
											</RoundedCTA>
										</span> */}
									</span>
								</section>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<div style={{
									height: '4px',
									backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
									backgroundPosition: "center center",
									backgroundSize: "cover"
								}}>

								</div>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className="richtext text-left ">
									<span>
										<h4>How We Drive Impact and Inclusion</h4>
										<p>We are committed to change and have built several internal DE&amp;I initiatives, including: Implicit Bias Workshops, Culture and Inclusion Communities in each local office, as well as strategic partnerships and sponsorships with ADCOLOR, Cannes Can: Diversity Collective, Unstereotype Alliance with the UN, Out Leadership, Women on Boards, ACT Responsible, 3% Movement, #MeToo Movement/Tarana Burke, The Ad Council and Free The Work, to name a few.</p>
									</span>
									<div className="df fxww jcc aic mr2rem">
										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-001.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-002.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-003.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-004.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-005.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-006.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-007.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-008.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-009.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0010.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0011.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0012.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0013.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0014.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0015.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0016.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0017.jpeg" />
										</div>
										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0018.png" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0019.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0020.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0021.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0022.jpeg" />
										</div>

										<div className="deandi-logo">
											<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/deandi-0023.jpeg" />
										</div>
									</div>

								</section>
							</GridContainer>
						</Fade>
					</section>
				</>
			)
		}
	}
}

DeAndI.defaultProps = {
	Request: new Object(),
}

const mapStateToProps = (state) => ({
	Request: state.request,
})

const mapDispatchToProps = (dispatch) => ({
	Fetch: (category) => dispatch(fetch(category)),
})
let connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeAndI)
connectedPage = withRouter(connectedPage)

export default connectedPage
