import React from "react"
import Actions from "./actions"
import Link from "./link"
import Fade from "react-reveal/Fade"
import GridContainer from '../blocks/GridContainer';

const Footer = ({ toggleShare, toggleSubscribe, toggleStopAnimation }) => (
	<footer id="footer" className="footer">
		{/* Get in touch section */}

		<section className="awards grid lrg">
			<Fade bottom delay={0}>
				<div className="awards-grid">
					{/* <div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-prnews.png" alt="pr news logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2020 Top Places to Work</div>
					</div> */}
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-fast.webp" alt="fast company logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2022 Most Innovative</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-lions.png" alt="cannes lions logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2022 #2 Global Network, #1 Global Strategy Agency &amp; #1 North America Network (<i>4th Consecutive Year</i>) 2020/2021 #1 Global Network</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-adage.png" alt="ad age logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2022, 2020 &amp; 2019 <span style={{whiteSpace: "nowrap"}}>A-List</span> &amp; 2020 Best Places to Work</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-adweek.png" alt="adweek logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2020 Global Agency of the Year</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-tofc.png" alt="the one club for creativity logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2020 Top Global Creative Network</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-andys.png" alt="the andys logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2021 &amp; 2020 Most Awarded Network</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-add.png" alt="ad and d logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2020 Design Agency of the Year</div>
					</div>
					<div className="grid-item">
						<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-report-2022.webp" alt="the good report logo" />
						<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">#1 Network<br />2021 &amp; 2020<br />6th Year Top 3 Network</div>
					</div>
				</div>
			</Fade>
		</section>

		<section className="divider" />

		<section className="cta over-hidden">
			<Fade bottom delay={0}>
				<div className="t-h2">Get in touch</div>
			</Fade>
			<Fade bottom delay={200}>
				<div className="footer-links">
					<Link url="/contact-us" classProp="btn btn-link t-link">
						Become A Client
					</Link>
					<Link url="/careers/join-our-team" classProp="btn btn-link t-link">
						Join Our Team
					</Link>
				</div>
			</Fade>
		</section>


		<Actions toggleShare={toggleShare} toggleStopAnimation={toggleStopAnimation} toggleSubscribe={toggleSubscribe} />

		{/* access section */}
		<section className="access">
		<div style={{
			display: 'grid',
			gridColumn: 'auto',
			gap: '1rem',
			gridAutoFlow: 'column',
			fontSize: '12px',
			fontFamily: 'code-pro',
			textDecoration: 'underline',
			cursor: 'pointer',
			alignItems: "center"
		}}>
				<a className="" href="https://fcbchi.com/media/5vxbt5xn/fcb-ipg-ca-privacy-notice-requirements.pdf" target="_blank">CCPA Privacy Notice</a>
				<Link
					url="/privacy-policy"
					classProp=""
				>
					Privacy Policy
				</Link>
				<Link
					url="/terms-and-conditions"
					classProp=""
				>
					Terms and Conditions
				</Link>
				<Link
					url="/press/press-kit"
					classProp=""
				>
					Press Kit
				</Link>
				<Link
					url="/contact-us"
					classProp="mr0">Contact Us</Link>
					<a id="ot-sdk-btn" className="ot-sdk-show-settings" style={{
					color: "white",
					fontFamily: "inherit",
					textDecoration: "underline",
					border: "none",
					fontSize: "inherit",
					padding: "revert",
					}}
					>Cookie Settings</a>
			</div>
			<div className="social-buttons df  pb2rem pt2rem">
				<div className="df jcsb aic">
					<a href="https://www.facebook.com/FCB.Global" title="Facebook" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-facebook" />
					</a>
					<a href="https://www.instagram.com/fcbglobal" title="Instagram" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-instagram" />
					</a>
					<a href="https://twitter.com/fcbglobal" title="Twitter" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-twitter" />
					</a>
					<a href="https://www.linkedin.com/company/fcbglobal/" title="Linkedin" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-linkedin" />
					</a>
					<a href="https://www.youtube.com/c/FCBglobal" title="youtube" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-youtube" />
					</a>
				</div>
				<Link url="/" classProp="footer-logo ff-cp fw700">
					{/* <span className="ico ico-font ico-fcb-logo" /> */}
					&copy;&nbsp;{new Date().getFullYear()} FCB Global, Inc. All rights reserved.
				</Link>
			</div>
		</section>
	</footer>
)

export default Footer
