export const Navigation = [
	{ name: "Home", path: "/" },
	{ name: "Work", path: "/work" },
	{ name: "Capabilities", path: "/capabilities" },
	{
		name: "Who We Are",
		path: "/who-we-are/who-is-fcb",
		children: [
			{ name: "Who Is FCB", path: "/who-we-are/who-is-fcb", class: "child hidden" },
			{ name: "Leadership", path: "/who-we-are/leadership", class: "child" },
			{ name: "Approach", path: "/who-we-are/approach", class: "child" },
			{ name: "Inclusion Commitment", path: "/who-we-are/de-and-i", class: "child" }
		] 		
	},
	{ name: "Where We Are", path: "/where-we-are" },
	{
		name: "Press",
		path: "/press/press-center",
		children: [
			{ name: "Press Center", path: "/press/press-center", class: "child hidden" },
			{ name: "Press Kit", path: "/press/press-kit", class: "child hidden" },
			// { name: "FCB Feed", path: "/press/fcb-feed", class: "child hidden" },
			// { name: "Thought Leadership", path: "http://fcbexchange.com/", class: "child hidden", blank: true }
		]
	},
	{
		name: "Careers",
		path: "/careers/join-our-team",
		children: [
			{ name: "Join Our Team", path: "/careers/join-our-team", class: "child hidden" },
		]
	},
	{ name: "Contact Us", path: "/contact-us" }
]
