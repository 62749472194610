import React, { Component } from "react"
import RichText from "../layout/richText"

class Modal extends Component {
	constructor(props) {
		super(props)

		this.state = {
			visibilityClass: "",
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				visibilityClass: "visible",
			})
		}, 200)
	}

	render() {
		const { visibilityClass } = this.state
		const { closeModal, modalClass, header, subtitle, content, modalClosing } = this.props

		return (
			<div className={`modal ${visibilityClass && !modalClosing ? visibilityClass : ""} ${modalClass ? modalClass : ""}`}>
				<div className="modal-bg" onClick={() => closeModal()}></div>
				<div className="modal-close" onClick={() => closeModal()}>
					<span className="ico ico-font ico-close"></span>
				</div>
				<div className="modal-container">
					<div className="modal-content-container">
						<div className="grid sml">
							<div className="row">
								<div className="modal-content">
									<div className="modal-header">
										<div className="t-h2">{header}</div>
										{(subtitle || subtitle !== "") && <h5 className="h8">{subtitle}</h5>}
									</div>
									<div className="modal-content">
										<RichText marginDisabled textAlign="left" dangerouslySet>
											{content}
										</RichText>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Modal
