import React, { Component } from "react";

class CapabilitiesList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { marginDisabled, gridSize, title, children } = this.props;

    return (
      <section
        className={
          "capabilities-list module-margin " +
          (marginDisabled ? "module-margin-disabled " : "")
        }
      >
        <section className="head">
          <div className={"grid " + (gridSize ? gridSize : "")}>
            <div className="row">
              <div className="col">
                {title && <h1 className="h2">{title}</h1>}
              </div>
            </div>
          </div>
        </section>

        <section className="content">{children}</section>
      </section>
    );
  }
}

export default CapabilitiesList;
