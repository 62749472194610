import React, { Component } from "react";

class CapabilitiesCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data: { name }
    } = this.props;

    return <p className="capability">{name}</p>;
  }
}
export default CapabilitiesCard;
