import React, { Component } from "react"
import lottie from "lottie-web"
import { TweenMax, EasePack, Power1, ScrollToPlugin } from "gsap/all"
import * as fcbJson from "../../../img/loader/fcb/fcb-loader.json"

class SiteLoader extends Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { scrollfix } = this.props

		const fcbOptions = {
			container: this.fcb,
			renderer: "svg",
			autoplay: false,
			loop: true,
			animationData: fcbJson,
			assetsPath: "/public/img/loader/fcb/images/"
		}

		this.fcbAnim = lottie.loadAnimation(fcbOptions)
		let x = 0
		let pause = true

		setTimeout(function() {
			lottie.setSpeed(1.75)
			lottie.play()
		}, 500)

		window.addEventListener("DOMContentLoaded", () => {
			const overlay = document.getElementById("transition")

			TweenMax.set(overlay, { y: "-100%" })

			pause = false
			this.fcbAnim.loop = false

			this.fcbAnim.addEventListener("complete", () => {
				TweenMax.to(window, 0.1, {
					scrollTo: 0,
					onComplete: () => {
						TweenMax.to(overlay, 0.55, {
							opacity: 0,
							ease: Power1.easeIn,
							onComplete: () => {
								this.container.parentNode.removeChild(this.container)
								TweenMax.set(overlay, { y: "0%", opacity: 1 })
								scrollfix()
							}
						})
					}
				})
			})
		})
	}

	render() {
		return (
			<div
				ref={event => {
					this.container = event
				}}
				className="site-loader-overlay"
			>
				<div
					ref={event => {
						this.fcb = event
					}}
					id="fcb-logo"
				/>
			</div>
		)
	}
}

export default SiteLoader
